import styled from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import shadow from '../../../styles/atoms/shadows';
import { font } from '../../../styles/atoms/typography';

export const Container = styled.div`
  display: none;
  background-color: ${colors.base.white};
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 32px;
  ${shadow('sm')}

  ${atMinWidth.lg`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `}

  && a {
    width: 100%;
  }

  &.sticky {
    ${atMinWidth.lg`
      position: sticky;
      top: 165px;
      z-index: 1000;
    `}
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .heading {
    ${font('display', 'sm', '700')}
    color: ${colors.gray[900]};
  }
`;

export const Body = styled.div`
  ${font('text', 'sm', '400')}
`;

export const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
  padding: 0px;
  margin: 0px;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  gap: 10px;

  svg {
    width: 24px;
    height: 24px;
  }

  p {
    ${font('text', 'sm', '500')}
    color: ${colors.gray[900]};
  }
`;
